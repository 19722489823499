import React from 'react';
import cx from 'classnames';

import { ButtonWithArrows } from '@commons/index';

import plusIcon from '@images/new-small-icons/plusIcon.svg';
import minusIcon from '@images/new-small-icons/minusIcon.svg';

import * as styles from './block.module.scss';

export const Block = ({ title, descriptionPoints, isActive, onToggle, buttonData, isMobile }) => {
  return (
    <div className={styles.block}>
      <div className={styles.header}>
        <h3>{title}</h3>
        {isMobile && (
          <>
            <div className={styles.iconContainer}>
              {isActive ? (
                <img loading="lazy" src={minusIcon} className={styles.minusIcon} alt="hide details" />
              ) : (
                <img loading="lazy" src={plusIcon} className={styles.plusIcon} alt="show details" />
              )}
            </div>
            <span className={styles.clickableArea} onClick={onToggle}></span>
          </>
        )}
      </div>
      <div className={cx(styles.description, !isActive && styles.hideDescription)}>
        <ul>
          {descriptionPoints.map((point) => (
            <li key={point}>{point}</li>
          ))}
        </ul>
        {buttonData && (
          <ButtonWithArrows
            key={buttonData.label}
            {...buttonData}
            classNames={{ buttonWithArrows: styles.buttonWithArrows }}
          />
        )}
      </div>
    </div>
  );
};
