import React from 'react';

import Layout from '@src/layouts';

import {
  MobileHeaderLayoutA,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  ContactButtonImageSection,
  NewClientSingleTestimonial,
  ColoredBlocksWithRedirects,
  OpacityScrolledSlider,
  TechStackWithCategories,
} from '@commons/index';

import { TwoBlocksWithOneActive } from '@pages-impl/data';

import SEO from '@commons/SEO';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  contactButtonImageSectionProps,
  opacityScrolledSliderProps,
  testimonialProps,
  coloredBlocksWithRedirectsProps,
  twoBlocksWithOneActiveProps,
  techStackWithCategoriesProps,
} from '@pages-impl/data/payload';

export default function Data(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Master your data with CodiLime"
      {...props}
    >
      <SEO {...SEOProps} />
      <MobileHeaderLayoutA {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <TwoBlocksWithOneActive {...twoBlocksWithOneActiveProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <TechStackWithCategories {...techStackWithCategoriesProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
