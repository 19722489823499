// extracted by mini-css-extract-plugin
export var buttonImageSectionButton = "payload-module--button-image-section-button--5e4ef";
export var buttonImageSectionContent = "payload-module--button-image-section-content--50694";
export var buttonImageSectionImageContainer = "payload-module--button-image-section-image-container--32e37";
export var buttonImageSectionInnerWrapper = "payload-module--button-image-section-inner-wrapper--75fba";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--88302";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--192fa";
export var coloredBlocksTitle = "payload-module--colored-blocks-title--de56c";
export var header = "payload-module--header--3ed29";
export var headerBorder = "payload-module--header-border--e4a42";
export var headerMobileBorder = "payload-module--header-mobile-border--9e5bf";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--7b4a8";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--609f6";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--804fa";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--a7fe4";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--eb4f9";
export var techStackRectangleStyles = "payload-module--tech-stack-rectangle-styles--85356";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--46e57";
export var techStackTitleStyles = "payload-module--tech-stack-title-styles--dc6c7";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--89058";
export var testimonialsInnerWrapper = "payload-module--testimonials-inner-wrapper--e4aa7";
export var twoBlocksSectionInnerWrapper = "payload-module--two-blocks-section-inner-wrapper--ae877";
export var underHeaderButton = "payload-module--under-header-button--8a8c4";
export var underHeaderContentWrapper = "payload-module--under-header-content-wrapper--eda44";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--2ff73";
export var underHeaderSliderInnerWrapper = "payload-module--under-header-slider-inner-wrapper--55e47";
export var underHeaderText = "payload-module--under-header-text--c1bb0";