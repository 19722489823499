import React, { useState, useEffect } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { Block } from './block/Block';

import * as styles from './two-blocks-with-one-active.module.scss';

export const TwoBlocksWithOneActive = ({ sectionProps, blocks }) => {
  const breakpoints = useBreakpoint();
  const [activeBlock, setActiveBlock] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    breakpoints.md ? setIsMobile(true) : setIsMobile(false);
  }, [breakpoints]);

  const handleBlockToggle = (blockIndex, secondBlockIndex) => {
    if (activeBlock === blockIndex) {
      setActiveBlock(secondBlockIndex);
    } else {
      setActiveBlock(blockIndex);
    }
  };

  return (
    <NewStandardSection
      {...sectionProps}
      id={sectionProps.id || 'services'}
      classNames={{ innerWrapper: sectionProps.classNames?.innerWrapper, titleStyles: styles.titleStyles }}
    >
      <div className={styles.containerWithBlocks}>
        {blocks.map((block, index) => (
          <Block
            key={block.title}
            {...block}
            isActive={activeBlock === index}
            onToggle={() => handleBlockToggle(index, 1 - index)}
            isMobile={isMobile}
          />
        ))}
      </div>
    </NewStandardSection>
  );
};
