import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';
import Neptune from '@images/logos-partners/Neptune-logo.svg';
import bannerImage from './images/banner.png';

import {
  snowflake,
  apacheSpark,
  cassandra,
  hadoop,
  jupyter,
  pyTorch,
  pandas,
  tensorFlow,
  kafka,
  kibana,
  mongoDB,
  oracle,
  scikitLearn,
  sciPy,
  plotly,
  postgreSQL,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Data Services - Unlock The Potential of Your Data | CodiLime',
  description: 'Transform your business with data services and achieve your goals. Check out CodiLime’s expertise.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: <>Data Services</>,
  titleHasBorder: true,
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
  },
  titleBorderWidth: styles.headerMobileBorder,
};

export const sectionUnderHeaderProps = {
  textPartOne:
    'In the digital economy, data has become a resource that is valued more than ever. Well-analyzed data leads to better-informed business decisions and overall improved product performance. At CodiLime, by combining data engineering and data science services, we help businesses achieve their goals.',
  contactButtonLabel: 'Empower your business',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
    contentWrapper: styles.underHeaderContentWrapper,
    allText: styles.underHeaderAllText,
    text: styles.underHeaderText,
    button: styles.underHeaderButton,
    sliderInnerWrapper: styles.underHeaderSliderInnerWrapper,
  },
};

export const twoBlocksWithOneActiveProps = {
  sectionProps: {
    title: 'Our data services',
    classNames: { innerWrapper: styles.twoBlocksSectionInnerWrapper },
  },
  blocks: [
    {
      title: 'Data engineering services',
      descriptionPoints: ['Data processing flows', 'Database case analysis', 'Performance tuning'],
      buttonData: {
        label: 'Discover the full range of our data engineering services.',
        href: 'https://codilime.com/services/data-engineering/#technology-stack',
      },
    },
    {
      title: 'Data science services',
      descriptionPoints: [
        'AI/ML introduction assessment',
        'Machine learning models and implementation',
        'AI/ML solution review & optimization',
      ],
      buttonData: {
        label: 'Discover the full range of our data science services.',
        href: 'https://codilime.com/services/data-science/#tech-stack',
      },
    },
  ],
};

export const opacityScrolledSliderProps = {
  title: 'How data services benefit your business',
  blocksGap: 48,
  topOpacity: 0.3,
  showOneBlock: true,
  textBlocks: [
    <>
      <span>Better-informed business decisions</span>
    </>,
    <>
      <span>Increase efficiency</span> and strategic approach
    </>,
    <>
      Mitigate potential risks and <span>avoid costly mistakes</span>
    </>,
    <>
      <span>Improve performance and quality</span> of your product
    </>,
  ],
  classNames: {
    innerWrapper: styles.opacitySliderInnerWrapper,
    titlesContainer: styles.opacitySliderTitlesContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.buttonImageSectionInnerWrapper,
    },
  },
  title: <>Unlock the power of data and transform your business</>,
  isH2: true,
  imageAlt: 'Unlock the power of data and transform your business',
  image: bannerImage,
  buttonTitle: 'Contact us',
  classNames: {
    customContent: styles.buttonImageSectionContent,
    imageContainer: styles.buttonImageSectionImageContainer,
    customButton: styles.buttonImageSectionButton,
  },
};

export const testimonialProps = {
  testimonial: {
    quote:
      'CodiLime’s expertise in software engineering was indispensable at the beginning of Neptune’s journey. ' +
      'CodiLime helped us build and release the first version of Neptune and convince investors that our product is ' +
      'a real game changer for data scientists. Without any hesitation, I can recommend CodiLime as a reliable ' +
      'technology partner for every tech startup.',
    author: 'Piotr Niedźwiedź - CEO',
    logo: Neptune,
    logoAlt: 'Neptune Labs',
    customClasses: 'neptune_logo',
  },

  classNames: {
    innerWrapper: styles.testimonialsInnerWrapper,
  },
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Explore our other services',
  subtitle:
    'Looking for more ways to take your business to the next level? Explore our full range of services and discover the right solutions for your business today.',
  blocks: [
    {
      text: (
        <>
          <span>Network professional</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>R&D services</span>
        </>
      ),
      link: paths.SERVICES.R_D,
    },
    {
      text: (
        <>
          <span>Test automation</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.TEST_AUTOMATION,
    },
    {
      text: (
        <>
          <span>DevOps services</span>
        </>
      ),
      link: paths.SERVICES.DEVOPS,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksInnerWrapper,
    rightPart: styles.coloredBlocksRightPart,
    title: styles.coloredBlocksTitle,
  },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Technology stack',
    subtitle:
      'We use diverse technology stacks to provide the best possible data solutions. Check out our data engineering and data science tech stacks:',
    classNames: { titlesContainer: styles.techStackTitlesContainer, titleStyles: styles.techStackTitleStyles },
  },
  classNames: {
    containerWithTwoColumns: styles.techStackContainerWithTwoColumns,
    singleCategory: styles.techStackSingleCategory,
    iconsForCategory: styles.techStackIconsForCategory,
    innerWrapper: styles.techStackInnerWrapper,
    rectangleStyles: styles.techStackRectangleStyles,
  },
  redirectButton: {
    label: 'See the full tech stack',
    href: 'https://codilime.com/services/data-science/#tech-stack',
  },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Data engineering',
      technologiesData: [snowflake, apacheSpark, cassandra, hadoop, kafka, kibana, mongoDB, oracle],
    },
  ],
  blocksForSecondColumn: [
    {
      categoryTitle: 'Data science',
      technologiesData: [jupyter, pyTorch, pandas, tensorFlow, scikitLearn, sciPy, plotly, postgreSQL],
    },
  ],
};
